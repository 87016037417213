import { useEffect, useState } from 'react';
import fm from 'format-message';
import useSiteSelectorQuery from 'queries/useSiteSelectorQuery';
import BackgroundDynamic from '@activebrands/core-web/components/BackgroundDynamic';
import Image from '@activebrands/core-web/components/Image';
import media from '@activebrands/core-web/config/media';
import RichText from '@activebrands/core-web/libs/storyblok/RichText';
import extractBackgroundOverlays from '@activebrands/core-web/libs/storyblok/utils/extract-background-overlays';
import transformFlexibleBackgrounds from '@activebrands/core-web/libs/storyblok/utils/transform-flexible-backgrounds';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import { setSiteSelectorCookie } from '@activebrands/core-web/utils/handle-site-selector-cookie';
import { getRedirectPathWithQuery } from '@activebrands/core-web/utils/query';
import { useLocation } from '@reach/router';
import ThemeButton from 'components/buttons/ThemeButton';
import KariTraaIcon from 'components/icons/KariTraaIcon';
import Heading from 'components/text/Heading';
import Paragraph from 'components/text/Paragraph';

const Wrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '12px',
    width: '100%',
    height: '100vh',
    zIndex: 1,
    transition: 'transform var(--transition-fast)',
    overflowY: 'auto',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: 'var(--color-text-site-selector)',
    background: 'var(--color-bg-backdrop)',
});

const Backdrop = styled('div', {
    backgroundColor: 'var(--color-bg-backdrop)',
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
});

const CountrySelectWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '32px',
    padding: '8px',
    zIndex: 2,

    [media.min['mobile.lg']]: {
        padding: '12px',
    },

    [media.min['tablet.lg']]: {
        display: 'grid',
        gridTemplateColumns: 'repeat(12, 1fr)',
    },
});

const CountryContent = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',

    [media.min['tablet.lg']]: {
        gridColumn: '4 / span 5',
        alignSelf: 'center',
    },
});

const CountryButtonsWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: '8px',

    [media.min['tablet.lg']]: {
        gridColumn: '4 / span 6',
    },
});

const SectionWrapper = styled('div', {
    width: '100%',
    position: 'relative',
    zIndex: '2',
    paddingBottom: '48px',
});

const siteSelector = () => {
    const [css] = useStyletron();
    const year = new Date().getFullYear();

    const {
        desktopBackground,
        mobileBackground,
        siteSelectorExternalHeading,
        siteSelectorExternalHeadingType,
        siteSelectorExternalLinks,
        siteSelectorExternalParagraph,
        siteSelectorStaticPageHeading,
        siteSelectorStaticPageHeadingType,
        siteSelectorLinks,
        siteSelectorParagraph,
    } = useSiteSelectorQuery();

    const transformedBackground = transformFlexibleBackgrounds([mobileBackground, null, desktopBackground], 1);
    const backgroundOverlays = extractBackgroundOverlays([[mobileBackground, null, desktopBackground]]);

    const location = useLocation();
    const [pathWithQuery, setPathWithQuery] = useState('/');
    useEffect(() => {
        setPathWithQuery(getRedirectPathWithQuery(location));
    }, [location]);

    return (
        <Wrapper>
            <BackgroundDynamic
                background={transformedBackground}
                backgroundOverlays={backgroundOverlays}
                loading="eager"
            />
            <Backdrop />
            <div
                className={css({
                    zIndex: '3',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    padding: '16px 16px 64px',
                })}
            >
                <KariTraaIcon color="var(--color-top-logo-site-selector)" width="167px" />
            </div>
            <div>
                {siteSelectorLinks?.length > 0 ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorStaticPageHeading || siteSelectorParagraph) && (
                                <CountryContent>
                                    {siteSelectorStaticPageHeading && (
                                        <Heading
                                            as={siteSelectorStaticPageHeadingType}
                                            fontKeys={['Primary/20_94_-1_700', null, null, 'Primary/32_94_-2_700']}
                                        >
                                            {siteSelectorStaticPageHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorParagraph && (
                                        <RichText
                                            $style={{ marginBottom: '32px' }}
                                            data={siteSelectorParagraph}
                                            fontKeys="Secondary/16_130_400"
                                        />
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorLinks.map(site => (
                                    <ThemeButton
                                        $style={{
                                            dispaly: 'flex',
                                            justifyContent: 'space-between',
                                            width: ['100%', null, 'calc(50% - 4px)'],
                                            padding: '10px 12px',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                        }}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        key={site.name}
                                        theme="defaultsecondary"
                                        onClick={() => setSiteSelectorCookie(site.path)}
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        width: '24px',
                                                        height: '16px',
                                                        marginRight: '8px',
                                                        borderRight: '2px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                className={css({ textAlign: 'left' })}
                                                fontKeys="Misc/16_100_-1_500_UC"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
                {siteSelectorExternalLinks?.length > 0 ? (
                    <SectionWrapper>
                        <CountrySelectWrapper>
                            {(siteSelectorExternalHeading || siteSelectorExternalParagraph) && (
                                <CountryContent>
                                    {siteSelectorExternalHeading && (
                                        <Heading
                                            as={siteSelectorExternalHeadingType}
                                            fontKeys={['Primary/20_94_-1_700', null, null, 'Primary/32_94_-2_700']}
                                        >
                                            {siteSelectorExternalHeading}
                                        </Heading>
                                    )}
                                    {siteSelectorExternalParagraph && (
                                        <RichText
                                            $style={{ marginBottom: '32px' }}
                                            data={siteSelectorExternalParagraph}
                                            fontKeys="Secondary/16_130_400"
                                        />
                                    )}
                                </CountryContent>
                            )}
                            <CountryButtonsWrapper>
                                {siteSelectorExternalLinks.map(site => (
                                    <ThemeButton
                                        $style={{
                                            dispaly: 'flex',
                                            justifyContent: 'space-between',
                                            width: ['100%', null, 'calc(50% - 4px)'],
                                            padding: '10px 12px',
                                            borderRadius: '8px',
                                            cursor: 'pointer',
                                        }}
                                        as="a"
                                        href={`${site.path}${pathWithQuery}`}
                                        key={site.name}
                                        theme="defaultsecondary"
                                    >
                                        <div
                                            className={css({
                                                display: 'flex',
                                                alignItems: 'center',
                                                height: 'auto',
                                                alignSelf: 'center',
                                                gridColumn: '1 / span 2',
                                            })}
                                        >
                                            {site.icon && (
                                                <div
                                                    className={css({
                                                        width: '24px',
                                                        height: '16px',
                                                        marginRight: '8px',
                                                        borderRight: '2px',
                                                    })}
                                                    src={site.icon}
                                                >
                                                    <Image
                                                        sizes={['24px']}
                                                        src={{ url: site?.icon, width: [24, 48, 72] }}
                                                    />
                                                </div>
                                            )}
                                            <Paragraph
                                                className={css({ textAlign: 'left' })}
                                                fontKeys="Misc/16_100_-1_500_UC"
                                            >
                                                {site.label}
                                            </Paragraph>
                                        </div>
                                    </ThemeButton>
                                ))}
                            </CountryButtonsWrapper>
                        </CountrySelectWrapper>
                    </SectionWrapper>
                ) : null}
            </div>
            <footer
                className={css({
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    backgroundColor: 'var(--color-bg)',
                    color: 'var(--color-text)',
                    width: '100%',
                    padding: '16px',
                    zIndex: '2',
                    borderTop: '1px solid var(--color-border)',
                })}
            >
                <KariTraaIcon color="var(--fill-logo-footer-site-selector)" width="167px" />
                {fm('© { year } Kari Traa', {
                    year,
                })}
            </footer>
        </Wrapper>
    );
};

export default siteSelector;
